import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import DatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "normalize.css";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "./assets/css/all.min.css";
import "./scss/_global.scss";
import "./scss/_header.footer.scss";
import "./scss/_homePage.scss";
import "./scss/_login.scss";
import "./scss/_forms.scss";
import "./scss/_about.scss";
import "./scss/_contact.scss";
import "./scss/_faq.scss";
import "./scss/_setting.scss";
import "./scss/_profile.scss";
import "./scss/_chat.scss";
import "./scss/_pages.scss";
import "./scss/_ar-Lang.scss";
import "./scss/_news.scss";
import { createI18n } from "vue-i18n";
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'


const app = createApp(App);
const i18n = createI18n({
  locale: "en",
  messages: loadLocaleMessages(),
});

createApp(App).use(store).use(router).use(i18n).use(VueTelInput).mount("#app");

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

app.component("DatePicker", DatePicker);
