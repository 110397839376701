<template>
  <nav class="navbar navbar-home p-0" :class="getMainNavClasses">
    <div class="col-md-12 navbar-expand-md sps sps--abv">
      <div class="container p-0">
        <div class="row m-0 align-items-center w-100">
          <div class="col-md-2 p-0">
            <a class="navbar-brand" href="/">
              <img :src="logo" alt="logo" class="logo" />
            </a>
            <button
              class="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavAltMarkup"
              aria-controls="navbarNavAltMarkup"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
              <span class="navbar-toggler-icon"></span>
              <span class="navbar-toggler-icon"></span>
            </button>
          </div>
          <div class="col-md-10 p-0">
            <div
              class="nav-item dropdown_lang dropdown_notification text-center px-0"
              ref="dropdown"
              v-if="isAuth"
            >
              <button class="btn btn-color btn_bell" data-toggle="dropdown">
                <i class="far fa-bell"></i>
                <span
                  v-if="notifications.length"
                  class="notification_new"
                ></span>
              </button>
              <div class="dropdown-menu dropdown-menu-right p-0 sub-menu">
                <div class="new_div" v-if="notifications.length">
                  <a
                    href="#"
                    class="dropdown-item notification_read"
                    v-for="(notification, index) in notifications"
                    :key="notification.id"
                    :div="index"
                    @click="
                      mark_notifications(
                        notification.id,
                        index,
                        notification.data.link
                      )
                    "
                  >
                    <span class="title">{{ notification.data.title }}</span>
                    <span class="message">{{ notification.data.message }}</span>
                  </a>
                </div>
                <div v-else>
                  <div class="dropdown-item">
                    <span class="message text-center">No New Notification</span>
                  </div>
                </div>
                <div class="col-12 text-center mb-2">
                  <router-link
                    class="btn btn-color btn_notif"
                    to="/notifications"
                    >{{ $t("common.AllNotification") }}</router-link
                  >
                </div>
              </div>
            </div>
            <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
              <ul class="navbar-nav">
                <li class="nav-item" v-for="main in main_menu" :key="main.key">
                  <router-link :to="{ path: main.item_url }">
                    {{ main.item_title }}
                  </router-link>
                </li>
                <!-- <li class="nav-item notification_btn">
                  <a class="nav-link" href="#"><i class="fal fa-bell"></i></a>
                </li> -->
                <li v-if="isAuth" class="user-menu" @click="toggleUserMenu()">
                  <div class="d-flex align-items-center">
                    <div v-if="check_image === null">
                      <img :src="icon" alt="" />
                    </div>
                    <div v-else>
                      <img :src="storage_url + check_image" alt="" />
                    </div>

                    <span>{{ name }}</span>
                    <i class="fal fa-chevron-down mx-1"></i>
                  </div>
                  <transition name="fade" appear>
                    <div class="sub-menu" v-if="userMenuOpen">
                      <div class="nav-item">
                        <router-link
                          to="/personalInformation"
                          class="nav-link ro"
                          ><i class="far fa-user mr-2"></i
                          >{{ $t("common.Profile") }}</router-link
                        >
                      </div>
                      <div class="nav-item">
                        <router-link to="/profile" class="nav-link"
                          ><i class="far fa-file-alt mr-2"></i>
                          {{ $t("common.MyRequests") }}</router-link
                        >
                      </div>
                      <div class="nav-item">
                        <a class="nav-link" v-on:click="logOut()">
                          <i class="far fa-sign-out mr-2"></i
                          >{{ $t("common.SignOut") }}
                        </a>
                      </div>
                    </div>
                  </transition>
                </li>
                <li v-else class="nav-item login_btn">
                  <router-link class="nav-link" to="/login">
                    <i class="far fa-user mr-2"></i>
                    {{ $t("common.SignIn") }}
                  </router-link>
                </li>
                <li class="nav-item dropdown_lang text-center" ref="dropdown">
                  <button
                    class="btn btn-color"
                    data-toggle="dropdown"
                    @click="toggleDropdown"
                  >
                    {{ this.languageDefult.name }}
                  </button>
                  <div
                    class="dropdown-menu dropdown-menu-right p-0 sub-menu"
                    :class="{ show: isDropdownOpened }"
                  >
                    <a
                      v-for="language in languages"
                      :key="language.key"
                      href="#"
                      class="dropdown-item"
                      :class="
                        selectedLanguage === language.code ? 'active' : ''
                      "
                      @click.prevent="
                        changeLanguage(language.code, language.name)
                      "
                    >
                      {{ language.name }}
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import * as $ from "jquery";

import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import AuthService from "@/services/AuthService";
const toaster = createToaster({});

var base_url = process.env.VUE_APP_BASE_URL + "api";
var storage_url = process.env.VUE_APP_BASE_URL + "storage/";

export default {
  name: "AppHeader",
  data() {
    return {
      name: JSON.parse(localStorage.getItem("user_name")),
      check_image: JSON.parse(localStorage.getItem("check_image")),
      storage_url: process.env.VUE_APP_BASE_URL + "storage/",
      icon: JSON.parse(localStorage.getItem("icon")),
      logo: JSON.parse(localStorage.getItem("logo")),

      isAuth: false,
      userMenuOpen: false,
      isDropdownOpened: false,
      userObj: {},
      languages: [],
      languageDefult: "",
      main_menu: [],
      currentLang: "en",
      notifications: [],

      scrollingUp: false,
      scrollingDown: false,
      prevScrollpos: window.pageYOffset,
    };
  },
  beforeMount() {
    this.get_languages();
    this.get_lang_default();
    this.Personal_info();
    this.get_menu();
    this.handleScroll();
    this.get_icon();
    this.get_notification();

    if (this.logo === null) {
      this.get_icon();
    }
  },
  computed: {
    getMainNavClasses() {
      return {
        "scroll-up": this.scrollingUp,
        "scroll-down": this.scrollingDown,
      };
    },
  },

  methods: {
    Personal_info() {
      var params = {
        token: JSON.parse(localStorage.getItem("token")),
      };
      axios.post(base_url + "/me", params).then((response) => {
        this.isAuth = response.data.success;
        localStorage.setItem(
          "user_name",
          JSON.stringify(response.data.data.name)
        );
        localStorage.setItem(
          "image",
          JSON.stringify(storage_url + "/" + response.data.data.image)
        );
        localStorage.setItem(
          "check_image",
          JSON.stringify(response.data.data.image)
        );
        localStorage.setItem(
          "nick_name",
          JSON.stringify(response.data.data.nick_name)
        );
        localStorage.setItem("phone", JSON.stringify(response.data.data.phone));
        localStorage.setItem(
          "birth_date",
          JSON.stringify(response.data.data.birth_date)
        );
        localStorage.setItem(
          "gender",
          JSON.stringify(response.data.data.gender)
        );
        localStorage.setItem(
          "country_id",
          JSON.stringify(response.data.data.country_id)
        );
        // this.userObj = response.data.data;
        // thisuserObj.image_url =
        // process.env.VUE_APP_BASE_URL + "storage/" + response.data.data.image;
      });
    },
    logOut() {
      AuthService.logout()
        .then((response) => {
          this.isAuth = false;
          toaster.info(response.data.message);
          this.$router.push({ path: "/" }).then(() => {
            this.$router.go();
          });
        })
        .catch((error) => {
          let res = error.response;
          toaster.error(res.data.message);
        });
    },
    changeLanguage(locale, name) {
      this.$i18n.locale = locale;
      this.selectedLanguage = locale;
      this.languageDefult.name = name;
      localStorage.setItem("lang", this.$i18n.locale);
      this.isDropdownOpened = false;
      location.reload();
    },
    get_languages() {
      axios
        .get(base_url + "/get_all_languages")
        .then((response) => {
          this.languages = response.data.data;
        })
        .catch((error) => {
          let res = error.response;
          toaster.error(res.data.message);
        });
    },
    get_lang_default() {
      axios
        .get(base_url + "/get_default_language")
        .then((response) => {
          this.languageDefult = response.data.data;
          this.$i18n.locale = this.languageDefult.code;
          this.selectedLanguage = this.languageDefult.code;
          var activeTempShopTab = (this.currentLang =
            localStorage.getItem("lang"));
          if (!activeTempShopTab) {
            localStorage.setItem("lang", this.$i18n.locale);
          } else {
            axios
              .get(base_url + "/getLanguageByCode/" + activeTempShopTab)
              .then((respons) => {
                this.languageDefult = respons.data.data;
                this.$i18n.locale = this.languageDefult.code;
                this.selectedLanguage = this.languageDefult.code;
              });
          }
        })
        .catch((error) => {
          let res = error.response;
          toaster.error(res.data.message);
        });
    },
    get_menu() {
      var params = {
        slug: "main_menu",
        locale: localStorage.getItem("lang"),
      };
      axios
        .get(base_url + "/getMenu", { params: params })
        .then((response) => {
          this.main_menu = response.data.data.items;
          //console.log(this.main_menu);
        })
        .catch((error) => {
          let res = error.response;
          toaster.error(res.data.message);
        });
    },
    get_icon() {
      axios
        .get(base_url + "/getSettingsByKey/site_settings")
        .then((response) => {
          this.icon = this.storage_url + response.data.data.icon;
          localStorage.setItem("icon", JSON.stringify(this.icon));
          localStorage.setItem(
            "wh_logo",
            JSON.stringify(this.storage_url + response.data.data.wh_logo)
          );
          localStorage.setItem(
            "logo",
            JSON.stringify(this.storage_url + response.data.data.logo)
          );
        })
        .catch((error) => {
          let res = error.response;
          toaster.error(res.data.message);
        });
    },
    scrollNow() {
      const currentScrollPos = window.pageYOffset;

      if (currentScrollPos == 0) {
        this.scrollingUp = false;
        this.scrollingDown = false;
        return;
      }

      if (currentScrollPos < 50) return; // set offset here

      if (this.prevScrollpos > currentScrollPos) {
        // up
        this.scrollingDown = false;
        this.scrollingUp = true;
      } else {
        // down
        this.scrollingUp = false;
        this.scrollingDown = true;
      }

      this.prevScrollpos = currentScrollPos;
    },

    handleScroll() {
      let doScoll;

      window.onscroll = () => {
        clearTimeout(doScoll);
        doScoll = setTimeout(this.scrollNow, 0); // firing less scroll events
      };
    },
    toggleUserMenu() {
      this.userMenuOpen = !this.userMenuOpen;
      if (!this.userMenuOpen) $(".navbar-collapse").collapse("hide");
    },
    get_notification() {
      var params = {
        locale: localStorage.getItem("lang"),
        token: JSON.parse(localStorage.getItem("token")),
      };

      axios
        .post(base_url + "/get-unreadNotifications", params)
        .then((response) => {
          this.notifications = response.data.data;
        });
    },
    mark_notifications(id, index, link) {
      var params = {
        locale: localStorage.getItem("lang"),
        token: JSON.parse(localStorage.getItem("token")),
        id: id,
      };
      console.log(id);
      axios
        .post(base_url + "/mark-notifications", params)
        .then(() => {
          this.notifications.splice(index, 1);
          if (link) {
            window.location.href = link;
          }
        })
        .catch((error) => {
          let res = error.response;
          toaster.error(res.data.message);
        });
    },
  },
};

$(function () {
  $(document).click(function () {
    $(".navbar-collapse").collapse("hide");
    $(".user-menu").on("click", function (e) {
      e.stopPropagation();
    });
  });
});
</script>
