import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({});
let token = JSON.parse(localStorage.getItem("token"));
let locale = localStorage.getItem("lang");

export default {
  base_url: process.env.VUE_APP_BASE_URL + "api",
  headers: {
    Authorization: "Bearer " + token,
  },
  getSectionBySlug(slug) {
    return axios
      .get(this.base_url + "/getSection", {
        params: {
          slug: slug,
          locale: locale,
        },
      })
      .catch((error) => {
        let res = error.response;
        toaster.error(res.data.message);
      });
  },
};
