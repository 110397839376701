<template>
  <div class="home">
    <div class="top_banner_home">
      <div class="container">
        <div class="row m-0 align-items-center">
          <div class="col-md-6">
            <div class="left_side">
              <h3>
                {{
                sections["homepage_content_left_box"]?.items["title_1"]?.value
                }}
              </h3>
              <p>
                {{
                sections["homepage_content_left_box"]?.items["text"]?.value
                }}
              </p>
              <h6>
                {{
                sections["homepage_content_left_box"]?.items["title_2"]?.value
                }}
              </h6>
              <ul>
                <li>
                  <a
                    :href="
                      sections['homepage_content_left_box']?.items[
                        'image_link_1'
                      ]?.value
                    "
                  >
                    <img
                      :src="
                        storage_url +
                        sections['homepage_content_left_box']?.items['image_1']
                          .value
                      "
                      alt
                    />
                  </a>
                </li>
                <li>
                  <a
                    :href="
                      sections['homepage_content_left_box']?.items[
                        'image_link_2'
                      ]?.value
                    "
                  >
                    <img
                      :src="
                        storage_url +
                        sections['homepage_content_left_box']?.items['image_2']
                          .value
                      "
                      alt
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-6 row right_side pt-5">
            <div class="col-6">
              <img
                :src="
                  storage_url +
                  sections['homepage_content_right_box']?.items['image_1']
                    ?.value
                "
                alt
              />
            </div>
            <div class="col-6">
              <img
                :src="
                  storage_url +
                  sections['homepage_content_right_box']?.items['image_2']
                    ?.value
                "
                alt
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about_sec pt-5 pb-5">
      <div class="container">
        <div class="row m-0" style="justify-content: space-evenly">
          <div class="col-12 mb-4">
            <h4>{{ sections["homepage_large_text_box"]?.items["title"]?.value }}</h4>
            <h6>{{ sections["homepage_large_text_box"]?.items["text"]?.value }}</h6>
          </div>
          <div class="col-md-6 mb-20">
            <div class="all_icon_div">
              <a
                :href="
                  sections['homepage_small_content_box_1']?.items['link'].value
                "
              >
                <div class="col-12">
                  <div class="icon">
                    <img
                      :src="
                        storage_url +
                        sections['homepage_small_content_box_1']?.items['image']
                          .value
                      "
                      class="mx-auto"
                      alt
                    />
                  </div>
                  <h3>
                    {{
                    sections["homepage_small_content_box_1"]?.items["title"]
                    .value
                    }}
                  </h3>
                  <p>
                    {{
                    sections["homepage_small_content_box_1"]?.items["text"]
                    .value
                    }}
                  </p>
                </div>
              </a>
            </div>
          </div>
          <div class="col-md-6 mb-20">
            <div class="all_icon_div">
              <a
                :href="
                  sections['homepage_small_content_box_2']?.items['link'].value
                "
              >
                <div class="col-12">
                  <div class="icon">
                    <img
                      :src="
                        storage_url +
                        sections['homepage_small_content_box_2']?.items['image']
                          .value
                      "
                      class="mx-auto"
                      alt
                    />
                  </div>
                  <h3>
                    {{
                    sections["homepage_small_content_box_2"]?.items["title"]
                    .value
                    }}
                  </h3>
                  <p>
                    {{
                    sections["homepage_small_content_box_2"]?.items["text"]
                    .value
                    }}
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="featured_announcemenet_box.length" class="news_sec mb-5 pt-5 mt-4">
      <div class="container">
        <carousel :autoplay="7000" pause-autoplay-on-hover :wrap-around="true" :items-to-show="1">
          <slide v-for="slide in featured_announcemenet_box" :key="slide.key">
            <div class="carousel__item">
              <div class="row m-0">
                <div class="col-md-6">
                  <img :src="storage_url + slide.image" alt />
                </div>
                <div class="col-md-6 pt-4 text-right">
                  <small>{{ $t("common.Announcemenet") }}</small>
                  <h3>{{ slide.title }}</h3>
                  <p>{{ slide.content }}</p>
                  <router-link
                    class="btn btn-color d-inline"
                    style="padding: 8px 18px;"
                    :to="'/announcemenet/' + slide.slug"
                  >{{$t('common.More_Details')}}</router-link>
                </div>
              </div>
            </div>
          </slide>

          <template #addons>
            <navigation />
          </template>
        </carousel>
        <div class="col-12 text-center mt-5">
          <router-link class="btn-color" style="min-width: 150px" to="/announcemenet">See More</router-link>
        </div>
      </div>
    </div>

    <div class="innovation_app">
      <div class="container">
        <div class="row m-0 align-items-center">
          <div class="col-lg-6 text-center">
            <img
              :src="
                storage_url +
                sections['homepage_large_content_box_1']?.items['left_image']
                  .value
              "
              width="95%"
              style="width: 95%"
              alt
              class="innovation_img"
            />
          </div>
          <div class="col-lg-6 side_image">
            <h3>
              {{
              sections["homepage_large_content_box_2"]?.items["title"]?.value
              }}
            </h3>
            <p>
              {{
              sections["homepage_large_content_box_2"]?.items["text"]?.value
              }}
            </p>
            <div
              class="row mb-4"
              v-for="block in sections['homepage_small_blocks']?.items?.block
                ?.extra"
              :key="block.key"
            >
              <div class="col-lg-3 col-md-2 col-sm-3 col-12 d-flex align-items-center">
                <div class="icon_app color1" :style="{ 'background-color': block.block_style }">
                  <img :src="storage_url + block.icon" alt />
                </div>
              </div>
              <div class="col-lg-9 col-md-8 col-sm-9 col-12 px-2">
                <div class="text_app">
                  <h4>{{ block.title }}</h4>
                  <h6>{{ block.text }}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="featured_news_box.length" class="news_sec mb-5 pt-5 mt-4 news_sec_new">
      <div class="container">
        <carousel :autoplay="7000" pause-autoplay-on-hover :wrap-around="true" :items-to-show="1">
          <slide v-for="slide in featured_news_box" :key="slide.key">
            <div class="carousel__item">
              <div class="row m-0">
                <div class="col-md-6 pt-4 text-right">
                  <small>{{ $t("common.News") }}</small>
                  <h3>{{ slide.title }}</h3>
                  <p>{{ slide.content }}</p>
                  <router-link
                    class="btn btn-color d-inline"
                    style="padding: 8px 18px;"
                    :to="'/news/' + slide.slug"
                  >{{$t('common.More_Details')}}</router-link>
                </div>
                <div class="col-md-6">
                  <img :src="storage_url + slide.image" alt />
                </div>
              </div>
            </div>
          </slide>

          <template #addons>
            <navigation />
          </template>
        </carousel>
        <div class="col-12 text-center mt-5">
          <router-link class="btn-color" style="min-width: 150px" to="/news">See More</router-link>
        </div>
      </div>
    </div>
    <MobilaApp />
  </div>
</template>

<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({});

import MobilaApp from "@/components/MobilaApp";
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Navigation } from "vue3-carousel";

var base_url = process.env.VUE_APP_BASE_URL + "api";
// var storage_url = process.env.VUE_APP_BASE_URL + "storage/";

export default {
  name: "HomeView",

  components: {
    Carousel,
    Slide,
    Navigation,
    MobilaApp,
  },
  data() {
    return {
      storage_url: process.env.VUE_APP_BASE_URL + "storage/",
      sections: [],
      featured_announcemenet_box: [],
      featured_news_box: [],
    };
  },
  beforeMount() {
    this.homepage_sections();
    this.featured_announcemenet();
    this.featured_news();
  },
  methods: {
    homepage_sections() {
      var params = {
        slug: JSON.stringify([
          "homepage_content_left_box",
          "homepage_content_right_box",
          "homepage_large_text_box",
          "homepage_small_content_box_1",
          "homepage_small_content_box_2",
          "homepage_large_content_box_1",
          "homepage_large_content_box_2",
          "homepage_small_blocks",
        ]),
        locale: localStorage.getItem("lang"),
      };
      axios
        .get(base_url + "/getAllSections", { params: params })
        .then((response) => {
          this.sections = response.data.data;
        })
        .catch((error) => {
          let res = error.response;
          toaster.error(res.data.message);
        });
    },
    featured_announcemenet() {
      var params = {
        locale: localStorage.getItem("lang"),
      };
      axios
        .get(base_url + "/get_featured_announcemenet", { params: params })
        .then((response) => {
          this.featured_announcemenet_box = response.data.data;
        })
        .catch((error) => {
          let res = error.response;
          toaster.error(res.data.message);
        });
    },
    featured_news() {
      var params = {
        locale: localStorage.getItem("lang"),
      };
      axios
        .get(base_url + "/get_featured_news", { params: params })
        .then((response) => {
          this.featured_news_box = response.data.data;
        })
        .catch((error) => {
          let res = error.response;
          toaster.error(res.data.message);
        });
    },
  },
};
</script>
