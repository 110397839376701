<template>
  <div class="application_sec mt-4">
    <div class="container">
      <div
        class="row m-0 align-items-end"
        style="justify-content: space-between"
      >
        <div class="col-12 text-center">
          <h1>
            <span>{{ name }}</span>
            {{ section?.title?.value }}
          </h1>
        </div>
        <div class="col-md-3 app_text">
          <p>
            {{ section?.text?.value }}
          </p>
          <a :href="section?.button_link?.value">
            {{ section?.button_text?.value }}</a
          >
        </div>
        <div class="col-md-5 text-center app_screen">
          <img
            :src="storage_url + section?.large_image?.value"
            style="width: 100%"
            alt=""
          />
        </div>
        <div class="col-md-3 app_text_left">
          <img :src="storage_url + section?.small_image?.value" alt="" />
          <h6>{{ section?.download_text?.value }}</h6>
          <div v-for="app in section?.app_block?.extra" :key="app.key">
            <a :href="app.image_link">
              <img
                :src="storage_url + app.app_image"
                class="mb-3 apps_link"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CmsService from "@/services/CmsService";
export default {
  name: "MobilaApp",
  data() {
    return {
      storage_url: process.env.VUE_APP_BASE_URL + "storage/",
      section: [],
      name: "",
    };
  },
  beforeMount() {
    this.section = CmsService.getSectionBySlug("homepage_mobile_app_box").then(
      (response) => {
        this.section = response.data.data;
      }
    );
  },
  methods: {},
};
</script>
