import AuthMiddleware from "@/middleware/AuthMiddleware";
import NotAuth from "@/middleware/NotAuth";
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
//import AuthService from "@/services/AuthService.js";
//import * as auth from "../services/AuthService.js";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about-us",
    name: "about",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/contact_us",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactView.vue"),
  },
  {
    path: "/news",
    name: "News",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/NewsView.vue"),
  },
  {
    path: "/news/:slug",
    name: "NewsDetails",
    component: () => import("../views/NewsDetails.vue"),
  },
  {
    path: "/announcemenet",
    name: "Announcemenet",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AnnouncemenetView.vue"),
  },
  {
    path: "/announcemenet/:slug",
    name: "AnnouncemenetDetails",
    component: () => import("../views/AnnouncemenetDetails.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      middleware: NotAuth,
    },
    component: () => import("../views/auth/LoginView.vue"),
  },
  {
    path: "/forget",
    name: "forget",
    meta: {
      middleware: NotAuth,
    },
    component: () => import("../views/auth/ForgetView.vue"),
  },
  {
    path: "/notifications",
    name: "AllNotifications",
    meta: {
      middleware: AuthMiddleware,
    },
    component: () => import("../views/setting/AllNotifications.vue"),
  },
  {
    path: "/newPassword",
    name: "newPassword",
    meta: {
      middleware: NotAuth,
    },
    component: () => import("../views/auth/NewPasswordView.vue"),
  },
  {
    path: "/register",
    name: "register",
    meta: {
      middleware: NotAuth,
    },
    component: () => import("../views/auth/RegisterView.vue"),
  },
  {
    path: "/requestLawyer",
    name: "requestLawyer",
    meta: {
      middleware: AuthMiddleware,
    },
    component: () => import("../views/forms/RequestLawyer.vue"),
  },
  {
    path: "/legalConsultant",
    name: "legalConsultant",
    meta: {
      middleware: AuthMiddleware,
    },
    component: () => import("../views/forms/LegalConsultant.vue"),
  },
  {
    path: "/congratulations",
    name: "congratulations",
    component: () => import("../views/forms/congratulationsView.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("../views/faqView.vue"),
  },
  {
    path: "/personalInformation",
    name: "personalInformation",
    meta: {
      middleware: AuthMiddleware,
    },
    component: () => import("../views/setting/personalInformation.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      middleware: AuthMiddleware,
    },
    component: () => import("../views/profileView.vue"),
  },
  {
    path: "/request/:id",
    name: "request",
    meta: {
      middleware: AuthMiddleware,
    },
    component: () => import("../views/requestView.vue"),
  },
  {
    path: "/LawyerRequest/:id",
    name: "LawyerRequest",
    meta: {
      middleware: AuthMiddleware,
    },
    component: () => import("../views/LawyerRequest.vue"),
  },
  {
    path: "/customerServicesLegal/:id",
    name: "customerServicesLegal",
    meta: {
      middleware: AuthMiddleware,
    },
    component: () => import("../views/customerServicesLegal.vue"),
  },
  {
    path: "/customerServicesLawyer/:id",
    name: "customerServicesLawyer",
    meta: {
      middleware: AuthMiddleware,
    },
    component: () => import("../views/customerServicesLawyer.vue"),
  },

  {
    path: "/Verify",
    name: "Verify",
    component: () => import("../views/auth/VeryfiCongratulation.vue"),
  },
  {
    path: "/Verify_SMS",
    name: "Verify_SMS",
    component: () => import("../views/auth/VerifyEmail.vue"),
  },
  {
    path: "/:slug",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;
  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];
    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);
    return middleware[0]({ ...context, next: nextMiddleware });
  }
  return next();
});


export default router;
