import axios from "axios";

let token = JSON.parse(localStorage.getItem("token"));

export default {
  base_url: process.env.VUE_APP_BASE_URL + "api",
  headers: {
    Authorization: "Bearer " + token,
  },
  async login(params) {
    return axios.post(this.base_url + "/login", params);
  },
  async checkAuth() {
    return axios.get(this.base_url + "/check-auth", { headers: this.headers });
  },
  async myInfo() {
    return axios.post(this.base_url + "/me",
      { token: token },
      this.headers);
  },
  async logout() {
    localStorage.clear();
    return axios.post(
      this.base_url + "/logout",
      { token: token },
      this.headers
    );
  },
};
