import AuthService from "@/services/AuthService";

export default function ({ next, router }) {
  AuthService.checkAuth()
    .then(function () {
      return next();
    })
    .catch(function () {
      return router.push({ name: "login" });
    });
}
