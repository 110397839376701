import AuthService from "@/services/AuthService";

export default function ({ next, router }) {
    AuthService.checkAuth()
        .then(function () {
            return router.push({ name: "personalInformation" });
        })
        .catch(function () {
            return next();
        });
}
