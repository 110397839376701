<template>
  <footer>
    <div class="container">
      <div class="row m-0">
        <div class="col-lg-6 col-md-12 col-sm-12 col-12 mb-4">
          <img :src="wh_logo" class="logo_image" alt="" />
          <p>{{ footer_text?.value }}</p>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-12 mb-4">
          <div class="title">Company</div>
          <ul class="footer_list">
            <li v-for="footer1 in main_menu_footer1" :key="footer1.key">
              <router-link :to="{ path: footer1.item_url }">
                {{ footer1.item_title }}</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-12 mb-4">
          <div class="title">Our Policies</div>
          <ul class="footer_list">
            <li v-for="footer2 in main_menu_footer2" :key="footer2.key">
              <!-- <router-link :to="{ path: footer2.item_url }">
                {{ footer2.item_title }}</router-link
              > -->
              <a :href="naw_base_url + footer2.item_url">
                {{ footer2.item_title }}
              </a>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 col-md-4 col-sm-4 col-12 mb-4">
          <div class="title">Our Policies</div>
          <ul class="footer_list">
            <li v-for="social in site_Social_media" :key="social.key">
              <a :href="social.link">
                <i class="mr-2" :class="social.icon"></i>
                {{ social.name }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-12 copy_right">
        © 2022 Codumatic - All Rights Reserved
      </div>
    </div>
  </footer>
</template>
<script>
import axios from "axios";
import { createToaster } from "@meforma/vue-toaster";
import CmsService from "@/services/CmsService";
const toaster = createToaster({});

var base_url = process.env.VUE_APP_BASE_URL + "api";
// var storage_url = process.env.VUE_APP_BASE_URL + "storage/";

export default {
  name: "AppFooter",
  data() {
    return {
      main_menu_footer1: [],
      main_menu_footer2: [],
      wh_logo: JSON.parse(localStorage.getItem("wh_logo")),
      footer_text: [],
      site_Social_media: [],
      naw_base_url: "/",
    };
  },
  beforeMount() {
    this.get_menu_footer();
    this.get_menu_footer2();

    this.footer_text = CmsService.getSectionBySlug("home_footer_text_box").then(
      (response) => {
        this.footer_text = response.data.data.text;
      }
    );
    this.get_site_Social_media();
  },

  methods: {
    get_menu_footer() {
      var params = {
        slug: "footer_menu_1",
        locale: localStorage.getItem("lang"),
      };
      axios
        .get(base_url + "/getMenu", { params: params })
        .then((response) => {
          this.main_menu_footer1 = response.data.data.items;
          //console.log(this.main_menu_footer1);
        })
        .catch((error) => {
          let res = error.response;
          toaster.error(res.data.message);
        });
    },
    get_menu_footer2() {
      var params = {
        slug: "footer_menu_2",
        locale: localStorage.getItem("lang"),
      };
      axios
        .get(base_url + "/getMenu", { params: params })
        .then((response) => {
          this.main_menu_footer2 = response.data.data.items;
          //console.log(this.main_menu_footer2);
        })
        .catch((error) => {
          let res = error.response;
          toaster.error(res.data.message);
        });
    },
    get_site_Social_media() {
      var params = {
        locale: localStorage.getItem("lang"),
      };
      axios
        .get(base_url + "/getSettingsByKey/social_settings", { params: params })
        .then((response) => {
          this.site_Social_media = response.data.data;
        })
        .catch((error) => {
          let res = error.response;
          toaster.error(res.data.message);
        });
    },
  },
};
</script>
