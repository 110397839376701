<template>
  <div v-bind:class="[{ ar_lang: $i18n.locale == 'ar' }]">
    <AppHeader />
    <router-view />
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from "@/components/general/AppHeader.vue";
import AppFooter from "@/components/general/AppFooter.vue";

export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter,
  },
  // beforeMount() {
  //   this.$i18n.rtl = "0";
  //   //console.log(this.$i18n.rtl);
  // },
};
</script>
